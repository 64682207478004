import Vue from 'vue';
import { Events, EventBus } from '@/lib/EventBus';
import Logo from '@/components/Shared/Logo.vue';
import EmptyState from '@/components/Shared/EmptyState.vue';
import ShiftSelector from '@/components/Shared/ShiftSelector.vue';
import MainMenu from '@/components/Shared/MainMenu.vue';
import Confirm from '@/components/Shared/Confirm.vue';
import ContentLoading from '@/components/Shared/ContentLoading.vue';
import ActionAccessControl from '@/components/Shared/ActionAccessControl.vue';
import OfflineButton from '@/components/Shared/OfflineButton.vue';
import NoPermissionButton from '@/components/Shared/NoPermissionButton.vue';
import PrintWindow from '@/components/Shared/PrintWindow.vue';
import ShiftPrinter from '@/components/ShiftBoard/SubComponents/ShiftPrinter.vue';
import ShiftReports from '@/components/ShiftBoard/SubComponents/ShiftReports.vue';
import StandardDialog from '@/components/Shared/Common/StandardDialog.vue';
import StandardCombobox from '@/components/Shared/Common/StandardCombobox.vue';

const Fragment = require('vue-fragment').default;
Vue.use(Fragment.Plugin);
Vue.component('app-logo', Logo);
Vue.component('empty-state', EmptyState);
Vue.component('shift-selector', ShiftSelector);
Vue.component('main-menu', MainMenu);
Vue.component('confirm', Confirm);
Vue.component('content-loading', ContentLoading);
Vue.component('v-access-control', ActionAccessControl);
Vue.component('offline-btn', OfflineButton);
Vue.component('no-permission-btn', NoPermissionButton);
Vue.component('print-window', PrintWindow);
Vue.component('shift-printer', ShiftPrinter);
Vue.component('shift-reports', ShiftReports);
Vue.component('standard-dialog', StandardDialog);
Vue.component('standard-combobox', StandardCombobox);

Vue.mixin({
    data() {
        return {
            $screenSize: localStorage.getItem('screenSize') || 'm'
        };
    },
    created() {
        EventBus.$on(Events.ScreenSizeChanged, (this as any).onScreenSizeChanged);
    },
    methods: {
        $error(error) {
            /* eslint-disable no-console */
            console['error'](error);
            /* eslint-enable no-console */
        },
        $preventEvent(event) {
            event.preventDefault();
        },
        onScreenSizeChanged(size: string) {
            (this as any).$screenSize = size;
        },
        $useSmallIcons() {
            return (this as any).$screenSize == 'x';
        }
    },
    destroyed() {
        EventBus.$off(Events.ScreenSizeChanged, (this as any).onScreenSizeChanged);
    }
});
