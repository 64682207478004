<template>
    <div class='pa-5'>
        <v-card v-if='data'>
            <v-card-title>
                <h1 class='title'>{{ 'Cycles' | term }}</h1>
                <v-spacer></v-spacer>
                <v-select
                    v-if='departments'
                    :items='departments'
                    item-value='id'
                    item-text='name'
                    v-model='selectedDepartment'
                    :label="$termSync('Department')"
                    single-line
                    hide-details
                    class='admin-table-filter'
                    :menu-props='{ dark: true }'
                >
                    <template v-slot:item='data'>
                        <template v-if='data.item.isGroup'>
                            <v-list-item-content
                                class='caption'
                                @click='$event.stopPropagation()'
                                v-text='data.item.name'
                            ></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content class='pl-5' v-text='data.item.name'></v-list-item-content>
                        </template>
                    </template>
                </v-select>
            </v-card-title>
            <empty-state
                v-if='!selectedDepartment'
                icon='mdi-alert-circle-outline'
                :message="'Select a ' + $termSync('Department')"
            ></empty-state>
            <v-alert color='error' v-if='!!selectedDepartment && !canHaveCycles'>
                This {{ 'Department' | term }} cannot have {{ 'Cycles' | term }}.
            </v-alert>

            <v-card-title v-if='!!selectedDepartment && filteredSystemCycles.length'>
                <h2 class='subtitle-1'>System {{ 'Cycles' | term }}</h2>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers='headers'
                :items='filteredSystemCycles'
                v-if='!!selectedDepartment && canHaveCycles && filteredSystemCycles.length'
            >
                <template v-slot:item='{ item }'>
                    <tr :class='{ dirty: item.dirty }'>
                        <td>{{ item.name }}</td>
                        <td>
                            <div class="my-1" style="max-width: 500px;">
                                <week-time-period-display
                                    :name="item.name"
                                    :colour="item.backgroundColour"
                                    :text-colour="item.foregroundColour"
                                >
                                </week-time-period-display>
                            </div>
                        </td>
                        <td>
                            <div class='no-wrap text-right'>
                                <v-btn text icon @click='edit(item)'>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <v-card-title v-if='!!selectedDepartment'>
                <h2 class='subtitle-1' v-if='filteredSystemCycles.length'>Custom {{ 'Cycles' | term }}</h2>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    color='secondary'
                    class='ml-3'
                    v-if='selectedDepartment && canHaveCycles'
                    @click='create()'
                >
                    <v-icon left>mdi-plus</v-icon>
                    Add
                </v-btn>
                <v-menu
                    v-model='importDialog'
                    max-width='310px'
                    :close-on-content-click='false'
                    v-if='!!selectedDepartment && canHaveCycles'
                >
                    <template v-slot:activator='{ on }'>
                        <v-btn v-on='on' small class='ml-1' color='secondary'>
                            <v-icon left>mdi-upload</v-icon>
                            Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref='inputUpload' type='file' accept='text/csv' @change='getFile($event)' />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color='secondary' @click='importData'>Upload</v-btn>
                            <v-btn text @click='importDialog = false'>cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn
                    small
                    class='ml-1'
                    color='gray'
                    v-if='!!selectedDepartment && filteredUserDefinedCycles.length'
                    @click='exportData()'
                >
                    <v-icon left>mdi-download</v-icon>
                    Export
                </v-btn>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers='headers'
                :items='filteredUserDefinedCycles'
                v-if='!!selectedDepartment && canHaveCycles'
            >
                <template v-slot:item='{ item }'>
                    <tr :class='{ dirty: item.dirty }'>
                        <td>{{ item.name }}</td>
                        <td>
                            <div class='my-1' style="max-width: 500px;">
                                <week-time-period-display
                                    :name="item.name"
                                    :colour="item.backgroundColour"
                                    :text-colour="item.foregroundColour"
                                    :is-delay="item.isDelay"
                                >
                                </week-time-period-display>
                            </div>
                        </td>
                        <td>
                            <div class='no-wrap text-right'>
                                <v-btn text icon @click='edit(item)'>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="1200" persistent>
            <template v-slot:title>
                <span v-if='selected.id'>Edit</span>
                <span v-else>Create</span>
                <span class='ml-2'>{{ 'Cycle' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref='form' :lazy-validation='true'>
                    <v-container fluid>
                        <v-row>
                            <v-col cols='9' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Name'
                                    :rules='[$rules.required(), $rules.maxLength(50)]'
                                    v-model='selected.name'
                                    counter='50'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='3' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Abbreviation'
                                    :rules='[$rules.notWhitespace(), $rules.maxLength(20)]'
                                    v-model='selected.abbrName'
                                    counter='20'
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style='min-height:55px;'>
                            <v-col cols='12' class>
                                <template v-for='(task, index) in orderedTasks'>
                                    <div v-if='task && task.taskTypeId && !task.isDeleted && showing' :key="index" class="my-1" style="max-width: 500px;display:inline-block;position:relative !important;">
                                        <week-time-period-display
                                            :name="task.taskType.name"
                                            :colour="task.taskType.backgroundColour"
                                            :text-colour="task.taskType.foregroundColour"
                                            :is-delay="task.taskType.isDelay"
                                            :hide-name-if-delay="false"
                                        >
                                        </week-time-period-display>
                                    </div>
                                </template>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12' class='pa-2 subtitle'>{{ 'Tasks' | term }}</v-col>
                        </v-row>
                        <template v-for='task in orderedTasks'>
                            <v-row :key='task.order' v-if='!task.isDeleted'>
                                <v-col cols='3' class>
                                    <v-select
                                        autocomplete='off'
                                        :label="$termSync('Task') + ' Type'"
                                        :rules='[$rules.required()]'
                                        v-model='task.taskTypeId'
                                        :items='filteredTasks'
                                        item-value='id'
                                        item-text='name'
                                        hide-details
                                        :disabled='isSystemCycle(selected)'
                                        @change='changeTaskType(task, $event)'
                                    ></v-select>
                                </v-col>
                                <template v-if='isRateTaskType(task.taskType)'>
                                    <v-col cols='3' class>
                                        <v-text-field
                                            autocomplete='off'
                                            :label='`Default ${rateMetricUnit(task.taskType.rateMetric)} per hour`'
                                            :rules='[$rules.required()]'
                                            v-model='task.defaultRatePerHour'
                                            type='number'
                                        >
                                        </v-text-field>
                                    </v-col>
                                </template>
                                <template v-else>
                                    <v-col cols='3' class>
                                        <v-text-field
                                            autocomplete='off'
                                            label='Minutes'
                                            :rules="[
                                                    $rules.required(),
                                                    $rules.multipleOf(15, '15 minute increments only')
                                                ]"
                                            v-model='task.defaultTimeMinutes'
                                            type='number'
                                            @change='updateComputedHandle()'
                                        ></v-text-field>
                                    </v-col>
                                </template>
                                <v-col cols='3' class='my-auto' v-if='!isSystemCycle(selected)'>
                                    <v-btn text icon @click='up(task)'>
                                        <v-icon>mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn text icon @click='down(task)'>
                                        <v-icon>mdi-arrow-down</v-icon>
                                    </v-btn>
                                    <v-btn text icon @click='removeTask(task)'>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <v-row v-if='!isSystemCycle(selected)'>
                            <v-col cols='12' class='pa-2'>
                                <v-btn small color='secondary' @click='addTask()'>
                                    <v-icon left>mdi-plus</v-icon>
                                    Add {{ 'Task' | term }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='4' class='pa-2'>
                                <Transition name='wobble-in'>
                                    <v-text-field
                                        autocomplete='off'
                                        :label='`Metres to Advance`'
                                        :rules="[$rules.min(0, 'Must be greater than or equal to 0'), $rules.noMoreDecimalPointsThan(1,'Maximum 1 decimal point')]"
                                        v-model='selected.designMetresToAdvance'
                                        @change="onUpdateDesignMetresToAdvance"
                                        type='number'
                                        v-if='canHaveMetrestoAdvance'
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color="grey" v-on="on">mdi-information</v-icon>
                                                </template>
                                                <span>How far this cycle will advance a location. Leave blank or set to zero for strip cuts etc.</span>
                                            </v-tooltip>
                                        </template>
                                    </v-text-field>
                                </Transition>
                            </v-col>
                        </v-row>
                        <v-row class='mt-3'>
                            <v-col cols='5'>
                                <h2 class='pa-2 title'>{{ 'Cycle' | term }} Colour</h2>
                                <v-color-picker
                                    class='ma-2'
                                    v-model='selected.backgroundColour'
                                    :swatches='swatch'
                                    show-swatches
                                ></v-color-picker>
                            </v-col>
                            <v-col cols='5'>
                                <h2 class='pa-2 title'>Text Colour</h2>
                                <v-color-picker
                                    class='ma-2'
                                    v-model='selected.foregroundColour'
                                    :swatches='textSwatches'
                                    show-swatches
                                ></v-color-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='2'>Sample:</v-col>
                            <v-col cols='6'>
                                <div style="max-width: 500px;">
                                    <week-time-period-display
                                        :name="selected.name"
                                        :colour="selected.backgroundColour"
                                        :text-colour="selected.foregroundColour"
                                        :is-delay="selected.isDelay"
                                    >
                                    </week-time-period-display>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='canDelete(selected)' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color='gray'
                    text
                    @click='
                                showModal = false;
                                selected = null;
                            '
                >Cancel
                </v-btn
                >
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color='gray'
                    text
                    @click='
                                    showModal = false;
                                    selected = null;
                                '
                >Cancel
                </v-btn
                >
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref='confirm'></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import MineAreas from '@/lib/data/MineAreas';
import TaskTypes from '@/lib/data/TaskTypes';
import Cycles from '@/lib/data/Cycles';
import Palettes from '@/lib/Palettes';
import DownloadHelper from '@/lib/DownloadHelper';
import { CycleType } from '@/models/api/Enums/cycle-type';
import { RateMetric } from '@/models/api/Enums/rate-metric';
import { BoardType, CycleClasses, TaskClasses } from '@/models/api';
import { FormatWithPrecision } from '@/lib/services/Utils';
import { applyMetresAdvancedToCycleName } from '@/lib/services/Cycle';
import Vue from 'vue';
import WeekTimePeriodDisplay from '@/components/Board/TimePeriodDisplay.vue';

const rateTaskSampleTimeMinutes = 60; // TODO: How wide should rate tasks be?

export default {
    components: { WeekTimePeriodDisplay },
    data() {
        return {
            data: null,
            mineAreas: null,
            selected: null,
            departments: null,
            selectedDepartment: null,
            taskTypes: null,
            showModal: false,
            computedHandle: 1,
            showing: false,
            importDialog: false,
            file: null,
            headers: [
                { text: 'Name', value: 'name', sortable: false, width: '45%' },
                { text: 'Sample', value: 'x', sortable: false, width: '35%' },
                { text: '', value: 'displayOrder', sortable: false, align: 'right', width: '15%' }
            ],
            textSwatches: [['#000000'], ['#444444'], ['#999999'], ['#ffffff'], ['#ff3333']]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {
    },
    methods: {
        async loadData() {
            this.mineAreas = await MineAreas.get(['Departments', 'Departments.Palettes']);
            this.data = await Cycles.get(['Department', 'Tasks', 'Tasks.TaskType']);
            this.taskTypes = await TaskTypes.get();

            var departments = [];
            for (let mine of this.mineAreas) {
                departments.push({ id: mine.id, name: mine.name, isGroup: true });
                for (let dept of mine.departments) {
                    departments.push({
                        id: dept.id,
                        name: dept.name,
                        isGroup: false,
                        palette: Palettes.makePalette(dept.palettes),
                        boardType: dept.boardType
                    });
                }
            }
            this.departments = departments;
        },
        create() {
            this.edit({
                departmentId: this.selectedDepartment,
                tasks: [],
                backgroundColour: '#26a69a',
                foregroundColour: '#ffffff',
                cycleType: CycleType.Duration, // TODO: Remove CycleType entirely.
                cycleClass: CycleClasses.UserDefined
            });
        },
        edit(item) {
            this.selected = {
                ...item,
                tasks: item.tasks.map(t => ({ ...t }))
            };
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
            this.showPreview();
        },
        addTask() {
            var order = this.orderedTasks.length ? this.orderedTasks[this.selected.tasks.length - 1].order + 1 : 0;
            this.selected.tasks.push({
                order: order,
                dirty: true,
                isDeleted: false,
                rateMetric: null
            });
            this.updateComputedHandle();
        },
        updateComputedHandle() {
            this.showPreview();
            this.computedHandle += 1;
        },
        up(item) {
            var prev;
            for (let x of this.orderedTasks) {
                if (x != item) {
                    prev = x;
                    continue;
                }
                var newOrder = prev.order;
                prev.order = item.order;
                item.order = newOrder;
                prev.dirty = true;
                item.dirty = true;
                break;
            }
            this.updateComputedHandle();
        },
        down(item) {
            var isNext = false;
            for (let x of this.orderedTasks) {
                if (isNext) {
                    var newOrder = x.order;
                    x.order = item.order;
                    item.order = newOrder;
                    x.dirty = true;
                    item.dirty = true;
                    break;
                }
                if (x == item) {
                    isNext = true;
                }
            }
            this.updateComputedHandle();
        },
        changeTaskType(task, id) {
            var found = this.taskTypes.filter(x => x.id == id)[0];
            if (this.isRateTaskType(found)) {
                task.defaultTimeMinutes = null;
            } else {
                task.defaultTimeMinutes = found.defaultTimeMinutes;
            }
            task.defaultRatePerHour = null;
            task.taskType = found;
            this.updateComputedHandle();
        },

        async saveList() {
            for (let x of this.data) {
                if (!x.dirty) {
                    continue;
                }
                await this.updateCycle(x);

            }
            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Cycle')} Saved`);
            await this.loadData();
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if(!this.canHaveMetrestoAdvance)
                this.selected.designMetresToAdvance = null;

            if(this.selected.id) {
                await this.updateCycle(this.selected);
            } else {
                await this.createCycle(this.selected);
            }

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Cycle')} Saved`);
            this.showModal = false;
            this.selected = null;
            await this.loadData();
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Cycle'), {})) {
                await Cycles.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Cycle')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        removeTask(task) {
            task.isDeleted = true;
        },
        onUpdateDesignMetresToAdvance(newValue) {
            this.selected.name=applyMetresAdvancedToCycleName(this.selected.name, Number.parseFloat(newValue));
        },
        computeTaskWidth(task) {
            if (this.isRateTaskType(task.taskType)) {
                return (rateTaskSampleTimeMinutes / this.totalTime) * 1000;
            } else {
                return (task.defaultTimeMinutes / this.totalTime) * 1000;
            }
        },
        showPreview() {
            this.showing = false;
            setTimeout(() => (this.showing = true), 1);
        },
        async exportData() {
            const result = await Cycles.export(this.selectedDepartment);
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            var selectedDept = this.departments.filter(x => x.id == this.selectedDepartment)[0];
            var deptName = selectedDept.name.replace(/[/\\?%*:|"<>]/g, '');
            DownloadHelper.download(`Cycles-${deptName}.csv`, blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await Cycles.import(this.selectedDepartment, this.file);
                this.importDialog = false;
                await this.loadData();
            } finally {
                this.$wait.end('saving');
            }
        },

        async getFile(e) {
            this.file = e.target.files[0];
        },

        isRateTaskType(t) {
            return t && t.rateMetric !== null;
        },

        isSystemCycle(cycle) {
            return cycle && cycle.cycleClass != CycleClasses.UserDefined;
        },

        canDelete(cycle) {
            return cycle && cycle.id && !this.isSystemCycle(cycle);
        },

        rateMetricUnit(rateMetric) {
            switch (rateMetric) {
                case RateMetric.Distance:
                    return 'meters';
                case RateMetric.WeightTonnes:
                    return 'tonnes';
                case RateMetric.WeightKilograms:
                    return 'kilograms';
                default:
                case RateMetric.Count:
                    return 'count';
            }
        },
        async updateCycle(cycle){
            return await Cycles.update({
                _type: 'UpdateCycleCommand',
                id: cycle.id,
                name: cycle.name,
                abbrName: cycle.abbrName,
                cycleClass: cycle.cycleClass,
                cycleType: cycle.cycleType,
                designMetresToAdvance: cycle.designMetresToAdvance,
                backgroundColour: cycle.backgroundColour,
                foregroundColour: cycle.foregroundColour,
                tasks: cycle.tasks.map(t => ({
                    _type: 'UpdateCycleTaskModel',
                    id: t.id,
                    taskTypeId: t.taskTypeId,
                    defaultTimeMinutes: t.defaultTimeMinutes,
                    defaultRatePerHour: t.defaultRatePerHour,
                    order: t.order,
                    isDeleted: t.isDeleted
                }))
            });
        },
        async createCycle(cycle){
            return await Cycles.create({
                _type: 'CreateCycleCommand',
                departmentId: cycle.departmentId,
                name: cycle.name,
                abbrName: cycle.abbrName,
                cycleClass: cycle.cycleClass,
                cycleType: cycle.cycleType,
                designMetresToAdvance: cycle.designMetresToAdvance,
                backgroundColour: cycle.backgroundColour,
                foregroundColour: cycle.foregroundColour,
                tasks: cycle.tasks.map(t => ({
                    _type: 'CreateCycleTaskModel',
                    taskTypeId: t.taskTypeId,
                    defaultTimeMinutes: t.defaultTimeMinutes,
                    defaultRatePerHour: t.defaultRatePerHour,
                    order: t.order
                }))
            });
        }
    },
    computed: {
        canHaveCycles() {
            if (!this.data || !this.selectedDepartment) {
                return false;
            }

            return true;
        },
        filteredSystemCycles() {
            if (!this.data || !this.selectedDepartment) {
                return [];
            }
            return this.data.filter(x => x.departmentId == this.selectedDepartment && this.isSystemCycle(x));
        },
        filteredUserDefinedCycles() {
            if (!this.data || !this.selectedDepartment) {
                return [];
            }
            return this.data.filter(x => x.departmentId == this.selectedDepartment && !this.isSystemCycle(x));
        },
        filteredTasks() {
            if (!this.taskTypes || !this.selectedDepartment) {
                return [];
            }
            return this.taskTypes
                .filter(x => x.departmentId == this.selectedDepartment)
                .filter(x => x.taskClass !== TaskClasses.DidNotFire)
                .filter(x => x.taskClass !== TaskClasses.Misfire)
                .filter(x => x.taskClass !== TaskClasses.Strip);
        },
        orderedTasks() {
            if (!this.computedHandle || !this.selected) {
                return [];
            }
            return this.selected.tasks.slice().sort((a, b) => a.order - b.order);
        },
        dirty() {
            for (let x of this.data) {
                if (x.dirty) {
                    return true;
                }
            }
            return false;
        },
        swatch() {
            var dept = this.departments.filter(x => x.id == this.selectedDepartment)[0];
            return dept ? dept.palette : [];
        },
        totalTime() {
            if (!this.computedHandle || !this.selected) {
                return 1000;
            }

            var sum = 0;
            for (let task of this.selected.tasks) {
                if (this.isRateTaskType(task.taskType)) {
                    sum += rateTaskSampleTimeMinutes;
                } else {
                    sum += parseInt(task.defaultTimeMinutes, 10);
                }
            }
            return sum;
        },
        selectedDepartmentModel() {
            return this.departments?.find(x => x.id === this.selectedDepartment);
        },
        canHaveMetrestoAdvance() {
            if(!this.computedHandle || !this.selected)
                return false;

            return this.selected.tasks.some(x => x.taskType?.taskClass === TaskClasses.Fire);
        }
    }
};
</script>